import Portal from "components/Portal"
import { AnimatePresence, motion } from "framer-motion"
import { fetchApps, fetchGames, fetchAll } from "queries"
import { ReactNode, useMemo } from "react"
import useGlobalStore from "stores/global"
import useRouterStore from "stores/routerStore"
import { AppInterface } from "types/types"
import useFetchApps from "utils/useFetchApps"
import useMediaQuery from "utils/useMediaQuery"
import StandaloneAppPreview from "../StandaloneAppPreview"
import InstantPage from "./InstantPageWrapper"

const InstantAppPreview = ({ children }: { children: ReactNode }) => {
  const id = useRouterStore((state) => state.router?.query.appId)

  const animationToggle = useGlobalStore((state) => state.animationToggle)
  const isMobile = useMediaQuery("(max-width: 900px)")
  const { data: apps } = useFetchApps({
    queryFn: fetchApps,
    queryKey: ["getApps"],
    enabled: false,
  })
  const { data: games } = useFetchApps({
    queryFn: fetchGames,
    queryKey: ["getGames"],
    enabled: false,
  })
  const { data: allApps } = useFetchApps({
    queryFn: fetchAll,
    queryKey: ["getAll"],
    enabled: false,
  })

  const appItem = useMemo(
    () =>
      allApps?.find((app: AppInterface) => app.trackId == id) ||
      apps?.find((app: AppInterface) => app.trackId == id) ||
      games?.find((app: AppInterface) => app.trackId == id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, apps, games, allApps],
  )
  if (isMobile)
    return (
      <>
        <Portal>
          <AnimatePresence>
            {!!id && (
              <InstantPage>
                <motion.div
                  style={{
                    paddingBottom: "calc(50px + env(safe-area-inset-bottom))",
                  }}
                  className="full-page-bg"
                  key={id + "app-preview-mobile"}
                  initial={animationToggle && { x: "70%", opacity: 0 }}
                  animate={
                    animationToggle && {
                      x: [700, -15, 0],
                      opacity: [0, 0.9, 1],
                      transition: { duration: 0.35 },
                    }
                  }
                  exit={
                    animationToggle
                      ? {
                          x: "60%",
                          opacity: 0,
                          transition: { duration: 0.18 },
                        }
                      : undefined
                  }
                >
                  <StandaloneAppPreview
                    id={Number(id)}
                    appItem={appItem}
                    isMobile
                  />
                </motion.div>
              </InstantPage>
            )}
          </AnimatePresence>
        </Portal>

        <>{children}</>
      </>
    )
  return (
    <>
      <AnimatePresence mode="wait">
        {!!id && (
          <motion.div
            style={{ paddingBottom: 50 }}
            key={id + "app-preview"}
            initial={animationToggle && { x: "70%", opacity: 0 }}
            animate={
              animationToggle && {
                x: [700, -15, 0],
                opacity: [0, 0.9, 1],
                transition: { duration: 0.35 },
              }
            }
            exit={
              animationToggle
                ? {
                    x: "60%",
                    opacity: 0,
                    transition: { duration: 0.18 },
                  }
                : undefined
            }
          >
            <StandaloneAppPreview id={Number(id)} appItem={appItem} />
          </motion.div>
        )}
      </AnimatePresence>
      {!id && <>{children}</>}
    </>
  )
}

export default InstantAppPreview
